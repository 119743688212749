@import 'app.scss';
.toggle {
  position: fixed;
  right: spacing(2);
  bottom: spacing(2);
  z-index: 3;

  @include from-breakpoint(lg) {
    display: none;
  }
}
