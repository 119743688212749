@import 'app.scss';
/**
 * Breadcrumbs
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.breadcrumbs {
  position: relative;
  z-index: 2;
  box-shadow: var(--shadow-tile);
  background-color: var(--color-bg-default);
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.wrapper {
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 0.07);
  padding-top: spacing(2);
  padding-bottom: spacing(2);
}

.list,
.listItem {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(2);
  align-items: center;
}

.listItem {
  @include until-breakpoint(md) {
    &:not(.secondToLastPage) {
      @include visually-hidden;
    }
  }
}

.list {
  margin-bottom: 0;
}

.link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  @include from-breakpoint(md) {
    text-decoration: underline;
    font-weight: bold;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.seperator {
  width: 0.375rem;
  height: auto;
}

.secondToLastPage {
  @include until-breakpoint(md) {
    flex-direction: row-reverse;

    .seperator {
      transform: rotate(180deg);
    }
  }
}

.currentPage {
  text-decoration: none;
  color: var(--color-gray-500);
  pointer-events: none;
}

.mobileLabel {
  @include from-breakpoint(md) {
    display: none;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
