/**
 * Shared input
 * Shared base styles for input, eg. buttons, text inputs, etc.
 */

@mixin shared-input {
  /* prettier-ignore */
  --shared-input-padding-vertical: #{spacing(2)};
  --shared-input-padding-horizontal: #{spacing(3)};

  appearance: none;
  display: inline-block;
  position: relative;
  transition-duration: var(--transition-duration-base);
  transition-property: border, color, background-color, box-shadow;
  transition-timing-function: var(--transition-duration-timing);
  padding: var(--shared-input-padding-vertical)
    var(--shared-input-padding-horizontal);
  vertical-align: middle;
  font: inherit; /* [1] */

  &[disabled],
  [disabled] > & {
    cursor: not-allowed;
  }

  @include from-breakpoint(md) {
    --shared-input-padding-horizontal: #{spacing(4)};
  }
}
