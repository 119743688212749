@import 'app.scss';
.button {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  transition: color var(--transition-base),
    background-color var(--transition-base);
  padding: spacing(1);
  color: var(--color-fg-on-primary-brand);
  color: currentcolor;
  pointer-events: all;
}

.icon {
  height: 24px;
}

.label {
  line-height: 150%;
  font-size: 0.75rem;
  font-weight: 700;

  .corporateButton & {
    @include from-breakpoint($menu-breakpoint) {
      font-size: 1.25rem;
    }
  }

  .jobsButton & {
    @include from-breakpoint($menu-breakpoint) {
      display: none;
    }
  }
}

.corporateButton {
  @include from-breakpoint($menu-breakpoint) {
    --height: 56px;

    flex-direction: row-reverse;
    gap: spacing(2);
    border-radius: calc(var(--height) / 2);
    background-color: var(--color-bg-default);
    padding-right: spacing(3);
    padding-left: spacing(3);
    height: var(--height);
    color: var(--color-fg-on-default);
  }
}
