/* stylelint-disable selector-class-pattern */

.u-typography-body {
  @include typography-body;
}

.u-typography-heading-1 {
  @include typography-heading-1;
}

.u-typography-heading-2 {
  @include typography-heading-2;
}

.u-typography-heading-3 {
  @include typography-heading-3;
}

.u-typography-heading-4 {
  @include typography-heading-4;
}

.u-typography-heading-5 {
  @include typography-heading-5;
}

.u-typography-heading-6 {
  @include typography-heading-6;
}
