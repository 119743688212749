@import 'app.scss';
/**
 * Accessibility Panel
 */

/**
 * ======================================
 * Elements
 * ======================================
 */
.panel {
  --border-radius: var(--border-radius-base);
  --horizontal-padding: 1rem;
  --panel-position: 1rem;

  position: fixed;
  bottom: var(--panel-position);
  left: var(--panel-position);
  z-index: 99;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-base);
  background-color: var(--color-bg-default);
  max-width: calc(100% - 2 * var(--panel-position));
  max-height: calc(100lvh - 2 * var(--panel-position));
  overflow-y: auto;

  @include from-breakpoint(md) {
    --horizontal-padding: 1.25rem;
    --panel-position: 1rem;
  }

  @include from-breakpoint(lg) {
    right: var(--panel-position);
    left: auto;
  }
}

.button {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  transition: background-color var(--transition-base);
  padding: 0.75rem var(--horizontal-padding);
  text-align: left;
  line-height: 1.33;
  font-size: 1.25rem;
  font-weight: 900;

  &:hover {
    background-color: var(--color-bg-primary-cta-hover);
  }

  &:focus-visible {
    @include generic-focus;
  }

  @include from-breakpoint(md) {
    font-size: 1.5rem;
  }
}

.label {
  @include until-breakpoint(md) {
    .button:not([aria-expanded='true']) & {
      @include visually-hidden;
    }
  }
}

.icon {
  width: 2rem;
  height: 2rem;

  &:first-child {
    margin-right: 0.25rem;
  }

  &:last-child {
    transform: rotate(180deg);

    [aria-expanded='true'] & {
      transform: rotate(0deg);
    }

    @include from-breakpoint(md) {
      margin-left: 1.25rem;
    }
  }
}

.content {
  display: grid;
  gap: 1.75rem;
  justify-items: flex-start;
  padding: 2rem var(--horizontal-padding);
  width: min-content;
  min-width: 100%;
  color: var(--color-fg-on-default);
  font-size: 1.25rem;

  .button:not([aria-expanded='true']) ~ & {
    display: none;
  }

  @include until-breakpoint(md) {
    [aria-expanded='true'] ~ & {
      width: 100%;
    }
  }
}

.fontSize {
  display: flex;
  flex-flow: wrap;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.sizeButton {
  flex-shrink: 0;
  transition: background-color var(--transition-base);
  border-radius: 50%;
  background-color: var(--color-bg-secondary-cta);
  padding: 0.25rem;
  color: var(--color-fg-on-secondary-cta);

  &:hover,
  &:focus {
    background-color: var(--color-bg-secondary-cta-hover);
  }

  &:focus-visible {
    @include generic-focus;
  }

  &:disabled {
    background-color: var(--color-bg-default);
    color: var(--color-fg-on-default);
    pointer-events: none;
  }
}

.sizeIcon {
  width: 2rem;
  height: 2rem;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
