@mixin container-width() {
  --horizontal-padding: #{spacing(2)};
  --max-width: var(--max-container-width);

  margin-right: auto;
  margin-left: auto;
  padding: 0 var(--horizontal-padding);
  width: 100%;
  max-width: calc(var(--max-width) + 2 * var(--horizontal-padding));

  @include from-breakpoint(sm) {
    --horizontal-padding: #{spacing(3)};
  }
}

@mixin container-grid() {
  --grid-spacing: #{spacing(1)};
  --grid-columns: 6;

  display: grid;
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
  gap: var(--grid-spacing);

  @include from-breakpoint(md) {
    --grid-spacing: #{spacing(4)};
    --grid-columns: 12;
  }
}

@mixin container() {
  @include container-width;
  @include container-grid;
}
