/*  =========================================================================
    Elements
    Unclassed HTML element selectors
    ========================================================================= */
html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-rendering: geometricprecision;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  display: flex;
  flex: 1;
  flex-direction: column;
}

main {
  flex: 1;
}

button {
  cursor: pointer;
}

img {
  display: block;

  // Makes sure each lazyload 3px by 3px is as big as its container
  width: 100%;
  height: auto;
}

dl {
  margin-bottom: 0;
}

label,
legend {
  display: block;
  font-weight: bold;

  &[for] {
    cursor: pointer; /* [1] */
  }

  small {
    font-weight: normal;
  }
}

a {
  transition: color var(--transition-duration-base)
    var(--transition-timing-base);
  text-decoration: underline;
  color: var(--color-text-base);
  text-decoration-skip-ink: auto;

  &:hover,
  &:focus-visible {
    text-decoration: none;
    color: var(--color-text-base);
  }

  &:focus-visible {
    @include generic-focus;
  }
}

hr {
  border: none;
  border-bottom: 1px solid var(--color-gray-500);
}

svg {
  display: block;
}

/* stylelint-disable-next-line selector-class-pattern */
.grecaptcha-badge {
  visibility: hidden;
}
