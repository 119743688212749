/*  =========================================================================
    Variables
    Global variables, config switches.
    You can NOT import this file other .scss files, instead it is imported in _app.js
    ========================================================================= */
:root,
::marker,
::after,
::before {
  --max-container-width: 80rem; // 1280px
  // Primary brand
  --color-red-100: #f6b4b7;
  --color-red-500: #e62832;

  // Secondary brand
  --color-green-500: #00afa5;
  --color-green-500-hover: #59cbc4;

  // Cta
  --color-yellow-500: #fcc300;
  --color-yellow-500-hover: #fdd859;

  // Cream
  --color-cream-500: #f0f0e6;

  // Status colors
  --color-success-100: #e4f1cc;
  --color-success-500: #00db2c;
  --color-success-900: #4a7c2a;
  --color-warning-100: #fff7e5;
  --color-warning-500: #ed6218;
  --color-warning-900: #ed6218;
  --color-error-050: #fffafa;
  --color-error-100: #ffe5e5;
  --color-error-500: #c80428;
  --color-error-900: #96031e;
  --color-info-100: #e5fffd;
  --color-info-500: #b1e7e3;

  // Gray colors
  --color-white: #fff;
  --color-gray-300: hsl(60deg 10% 80%);
  --color-gray-500: #706d6e;
  --color-gray-700: #595959;
  --color-black: #000;
  --color-text-base: var(--color-black);
  --color-border-base: var(--color-gray-700);

  // Transitions with timing from Google swift bezier
  --transition-duration-base: 150ms;
  --transition-duration-long: 300ms;
  --transition-timing-base: cubic-bezier(0.55, 0, 0.1, 1);
  --transition-base: var(--transition-duration-base)
    var(--transition-timing-base);

  // Borders
  --border-radius-small: 0.5rem; // 8px
  --border-radius-base: 0.625rem; // 10px
  --border-radius-medium: 0.75rem; // 12px
  --border-radius-large: 1.25rem; // 20px
  --border-radius-extra-large: 2rem; // 32px
  --border-color-base: var(--color-tertiary-500);

  // Shadows
  --box-shadow-base: 0 1.5rem 2rem rgb(0 0 0 / 0.05);
  --box-shadow-alternative: 1rem 1.5rem 7rem 1.5rem rgb(0 0 0 / 0.05);
  --box-shadow-float: 0 0.5rem 3rem rgb(0 0 0 / 0.07);

  // defaults to big touch targets
  --target-base-height: 48px;
  --target-small-height: var(--target-base-height);
  --target-tiny-height: var(--target-base-height);

  // Pagepart spacing
  --pagepart-vertical-spacing: #{spacing(8)};

  // Input spacing
  --horizontal-input-padding: #{spacing(2)};

  @include from-breakpoint(md) {
    --pagepart-vertical-spacing: #{spacing(10)};
  }

  @include from-breakpoint(lg) {
    --pagepart-vertical-spacing: #{spacing(12)};
  }

  @include from-breakpoint(xl) {
    --pagepart-vertical-spacing: #{spacing(14)};
  }

  // slightly smaller buttons for certain devices
  @media (pointer: fine) {
    --target-base-height: 45px;
    --target-small-height: 40px;
    --target-tiny-height: 1.5rem;
  }
}
