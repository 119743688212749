@import 'app.scss';
.menuToggle {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  transition: color var(--transition-base),
    background-color var(--transition-base);
  z-index: 1;
  padding: spacing(1);
  color: currentcolor;
  pointer-events: all;

  @include from-breakpoint(xl) {
    display: none;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  height: 1.5rem;
}

.icon {
  position: relative;
  width: 1.5rem; // 24px
  height: 1rem; // 16px

  span {
    position: absolute;
    transform: translateY(-50%);
    transition: all var(--transition-base);
    border-radius: 1px;
    background-color: currentcolor;
    height: 2px;

    &:nth-child(1) {
      top: 0;
      left: 0;
      width: 100%;
    }

    &:nth-child(2) {
      top: 50%;
      left: 0;
      width: 100%;
    }

    &:nth-child(3) {
      top: 100%;
      right: 0;
      width: 100%;
    }
  }
}

.label {
  line-height: 150%;
  font-size: 0.75rem;
  font-weight: 700;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.menuToggle.cross {
  color: var(--color-fg-on-surface);

  span {
    &:nth-child(1) {
      top: 7px;
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      opacity: 0;
      width: 0%;
    }

    &:nth-child(3) {
      top: 7px;
      left: 0;
      transform: rotate(-45deg);
    }
  }
}
