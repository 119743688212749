@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-Black.woff2') format('woff2'),
    url('/fonts/DINPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-Bold.woff2') format('woff2'),
    url('/fonts/DINPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-Medium.woff2') format('woff2'),
    url('/fonts/DINPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro.woff2') format('woff2'),
    url('/fonts/DINPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-Italic.woff2') format('woff2'),
    url('/fonts/DINPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Good Karma Smooth Wide Upright';
  src: url('/fonts/GoodKarmaSmooth-WideUpright.woff2') format('woff2'),
    url('/fonts/GoodKarmaSmooth-WideUpright.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('Arial');
  unicode-range: U+0040;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('Arial bold');
  unicode-range: U+0040;
  font-weight: 500;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('Arial bold');
  unicode-range: U+0040;
  font-weight: 700;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('Arial bold');
  unicode-range: U+0040;
  font-weight: 900;
}

body {
  @include typography-body;
}

.nextFonts {
  color: var(--color-fg-on-default);
  font-family: var(--font-family-base);
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;

  @include from-breakpoint(md) {
    margin-bottom: 1.25rem;
  }
}

h1 {
  @include typography-heading-1;
}

h2 {
  @include typography-heading-2;
}

h3 {
  @include typography-heading-3;
}

h4 {
  @include typography-heading-4;
}

h5 {
  @include typography-heading-5;
}

h6 {
  @include typography-heading-6;
}

::marker {
  color: var(--color-fg-interactive);
}
