@import 'app.scss';
/**
 * Quick links
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.quickLinks {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: spacing(2);
  margin-bottom: 0;

  @include from-breakpoint(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: spacing(3);
  }

  @include from-breakpoint(lg) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @include from-breakpoint(xl) {
    grid-template-columns: repeat(6, minmax(0, 11rem));
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.link {
  @include wave-animation(var(--color-bg-primary-cta-hover));

  display: flex;
  position: relative;
  flex-direction: column;
  gap: spacing(2);
  align-items: center;
  transition: var(--transition-base);
  border-radius: var(--border-radius-extra-large);
  box-shadow: var(--shadow-tile);
  background-color: var(--color-bg-alternative);
  padding: spacing(3) spacing(2);
  text-align: center;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 700;

  &:hover,
  &:focus {
    background-color: var(--color-bg-primary-cta);
  }

  @include from-breakpoint(md) {
    font-size: 1.5rem;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
