@import 'app.scss';
/**
 * Submenu
 */

/**
 * ======================================
 * Elements
 * ======================================
 */

.toggle {
  &[aria-expanded='true'] {
    @include triangle-marker(var(--color-bg-alternative));

    position: relative;
  }

  &.subMenuChildIsShown {
    --triangle-bg-color: var(--color-bg-default);
  }

  @include from-breakpoint($menu-breakpoint) {
    &[aria-expanded='true'] {
      .toggleIcon {
        transform: rotate(270deg);
      }
    }

    .toggleIcon {
      transform: rotate(90deg);
      transition: var(--transition-base);
      width: auto;
      height: 0.625rem; // 10px
    }
  }
}

.list {
  --spacing: #{spacing(6)};
  --link-width: 15.75rem;

  display: none;
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
  background-color: var(--color-bg-alternative);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: var(--color-fg-on-surface);

  &.subMenuIsShown {
    display: block;
  }

  @include from-breakpoint($menu-breakpoint) {
    &:not(.nestedList) {
      @include container-width;

      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: var(--border-radius-large);
      padding: var(--spacing) 0;
      width: 100%;
      height: 75dvh;
      max-height: 37.5rem; // 600px
    }
  }
}

.nestedList {
  @include from-breakpoint($menu-breakpoint) {
    left: calc(var(--link-width) + var(--spacing) * 2);
    background-color: var(--color-bg-default);
    padding: var(--spacing) 0;
    width: calc(100% - var(--link-width) - var(--spacing) * 2);
  }
}

.title {
  display: none;

  @include from-breakpoint($menu-breakpoint) {
    display: block;
    padding: 0 var(--spacing);

    .nestedList & {
      display: none;
    }
  }
}

.listItem {
  @include from-breakpoint($menu-breakpoint) {
    &:not(:last-child) {
      .subMenuLink:not([aria-expanded='true']) {
        border-bottom: 1px solid var(--color-border-base);
      }

      .nestedSubMenuLink {
        border-color: hsl(0deg 0% 85% / 1); // Match design
      }
    }
  }
}

.backButton {
  justify-content: flex-start;
  font-weight: normal;

  @include from-breakpoint($menu-breakpoint) {
    display: none;
  }
}

.subMenuLink {
  @include from-breakpoint($menu-breakpoint) {
    margin: 0 var(--spacing);
    padding: spacing(2) 0;
    width: var(--link-width);
    color: var(--color-fg-on-surface);
    font-size: 1.5rem;
    font-weight: 500;

    &:hover,
    &:focus {
      color: var(--color-fg-on-surface);
    }

    &[aria-expanded='true'] {
      margin: -1px 0 0;
      background-color: var(--color-bg-default);
      padding: calc(spacing(2) + 1px) var(--spacing);
      width: calc(var(--link-width) + var(--spacing) * 2);
    }

    .toggleIcon {
      margin-right: 0.625rem;
    }
  }
}

.topSubMenuLink {
  display: block;

  &::before {
    content: 'Alles over ';
  }
}

.nestedSubMenuLink {
  @include from-breakpoint($menu-breakpoint) {
    &::before {
      display: none;
    }
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
