@import 'app.scss';
/**
 * Menu
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transition: var(--transition-base);
  z-index: 1;
  background-color: rgb(0 0 0 / 0.7);
  padding-top: spacing(10);
  width: 100%;
  height: 100%;

  &.menuIsShown {
    display: block;
  }

  @include from-breakpoint($menu-breakpoint) {
    display: block;
    position: static;
    align-self: stretch;
    z-index: 1;
    background-color: transparent;
    padding: 0;
    padding-top: 0;
    width: auto;
    height: unset;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.container {
  @include until-breakpoint($menu-breakpoint) {
    display: flex;
    flex-direction: column;
    border-top-left-radius: var(--border-radius-extra-large);
    border-top-right-radius: var(--border-radius-extra-large);
    background-color: var(--color-bg-alternative);
    padding: spacing(4) spacing(3);
    height: 100%;
    overflow-y: auto;
  }
}

.container,
.nav,
.list {
  @include from-breakpoint($menu-breakpoint) {
    height: 100%;
  }
}

.closeMenu {
  align-self: flex-end;
}

.nav {
  position: relative;
  margin-top: spacing(4);

  @include from-breakpoint($menu-breakpoint) {
    position: unset;
    margin-top: 0;
  }
}

.list {
  margin-bottom: 0;

  @include from-breakpoint($menu-breakpoint) {
    display: flex;
    align-items: stretch;
  }
}

.matchMakerButton {
  margin-top: spacing(2);
  font-size: 1.25rem;

  @include from-breakpoint(xl) {
    display: none;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
