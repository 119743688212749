/* stylelint-disable selector-class-pattern */

// Margin bottom
.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-1 {
  margin-bottom: spacing(1);
}

.u-mb-2 {
  margin-bottom: spacing(2);
}

.u-mb-3\@from-md {
  @include from-breakpoint(md) {
    margin-bottom: spacing(3);
  }
}

.u-mb-4 {
  margin-bottom: spacing(4);
}

.u-mb-4\@from-md {
  @include from-breakpoint(md) {
    margin-bottom: spacing(4);
  }
}

.u-mb-6 {
  margin-bottom: spacing(6);
}

// Margin top
.u-mt-2 {
  margin-top: spacing(2);
}

.u-mt-16\@from-md {
  @include from-breakpoint(md) {
    margin-top: spacing(16);
  }
}
