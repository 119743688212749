@import 'app.scss';
.content {
  /**
  * Remove margin-bottom from last child of this container
  */
  > :last-child {
    margin-bottom: 0;
  }

  ul {
    // Align the left arrow with the left of the content
    padding-left: 24px;

    li {
      padding-left: 0.625rem;
      list-style-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z' fill='%2300AFA5'/%3E%3C/svg%3E%0A");
    }
  }
}
