@import 'app.scss';
.button {
  transition: var(--transition-base);
  border-radius: var(--border-radius-small);
  padding: 0.25rem;
  color: inherit;
}

.icon {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;

  span {
    position: absolute;
    transform: translateY(-50%);
    transition: all var(--transition-base);
    background-color: currentcolor;
    height: 2px;

    &:nth-child(1) {
      top: 45%;
      left: 15%;
      transform: rotate(45deg);
      width: 70%;
    }

    &:nth-child(2) {
      top: 45%;
      left: 15%;
      transform: rotate(-45deg);
      width: 70%;
    }
  }
}
