@mixin triangle-marker($background-color) {
  --triangle-height: 1rem;
  --triangle-width: 2.25rem;

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 calc(var(--triangle-width) / 2) var(--triangle-height);
    border-style: solid;
    border-color: transparent transparent $background-color;
    width: 0;
    height: 0;
    content: '';
  }
}
