/* stylelint-disable selector-class-pattern */

/**
 * Grid layout utility
 */

.u-grid {
  --grid-spacing: #{spacing(5)};

  display: grid;
  grid-gap: var(--grid-spacing);
}

.u-grid--2\@md {
  @include from-breakpoint(md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.u-grid--2\@lg {
  @include from-breakpoint(lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.u-grid--3\@md {
  @include from-breakpoint(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.u-grid--3\@lg {
  @include from-breakpoint(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.u-grid--spacing-3 {
  --grid-spacing: #{spacing(3)};
}

.u-grid--name-fields {
  @include from-breakpoint(md) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr) minmax(0, 3fr);
  }
}
