@mixin menu-link {
  @include typography-heading-5;

  display: flex;
  gap: spacing(1);
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border-base);
  padding: spacing(2) 0.625rem;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--color-fg-on-surface);
  font-size: 1.25rem;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    color: var(--color-fg-on-surface);
  }

  &:focus-visible {
    @include generic-focus;
  }

  @include from-breakpoint($menu-breakpoint) {
    border-bottom: none;
    padding: spacing(2) 0.875rem;
    line-height: 1.33;
    color: var(--color-fg-on-primary-brand);
    font-size: 1.125rem;
    font-weight: 700;

    &:hover,
    &:focus {
      color: var(--color-fg-on-primary-brand);
    }
  }
}
