@import 'app.scss';
/**
 * Modal
 */

/**
 * ======================================
 * Elements
 * ======================================
 */

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  transition: var(--transition-base);
  z-index: 10;
  background-color: rgb(0 0 0 / 0.7);
  width: 100%;
  height: 100%;

  &:not(.show) {
    display: none;

    ~ .modal {
      display: none;
    }
  }

  @include from-breakpoint($menu-breakpoint) {
    background-color: rgb(0 0 0 / 0.2);
  }
}

.modal {
  --top-spacing: #{spacing(5)};

  display: flex;
  position: fixed;
  top: var(--top-spacing);
  left: 50%;
  flex-direction: column;
  transform: translateX(-50%);
  z-index: 11;
  border-radius: var(--border-radius-extra-large)
    var(--border-radius-extra-large) var(--border-radius-small)
    var(--border-radius-extra-large); // bottom-right has smaller radius, because of scroll-bar on windows

  background-color: var(--color-bg-default);
  padding: spacing(4) 0 0;
  width: 100%;
  max-width: 40rem;
  max-height: calc(100vh - 2 * var(--top-spacing));
  color: var(--color-fg-on-default);
}

.header {
  display: flex;
  gap: spacing(1);
  justify-content: space-between;
  margin-bottom: spacing(3);
  padding: 0 spacing(3);
}

.content {
  padding: 0 spacing(3) spacing(4);
  overflow-y: auto;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: spacing(-3); // Pull over the padding on line 65
  padding: 0 spacing(3) spacing(4);
}

.title {
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 2rem;
  font-weight: 900;
  font-style: normal;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.modalContainer.searchModal {
  ~ .modal {
    @include from-breakpoint($menu-breakpoint) {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: var(--border-radius-large);
      max-width: calc(var(--max-width) + 2 * var(--horizontal-padding));
      height: auto;
      max-height: 75vh;
      max-height: 75dvh;
    }
  }

  @include from-breakpoint($menu-breakpoint) {
    display: none;
  }
}

.modalContainer.quickLinksModal ~ .modal {
  background-color: var(--color-bg-primary-brand);
  color: var(--color-fg-on-primary-brand);
}

.modal.copyModal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--border-radius-large);
  width: 90dvw;
  height: max-content;

  @include from-breakpoint(md) {
    width: 100dvw;
  }
}
