/**
 * Umbrella link utility
 */

/**
 * ======================================
 * Block
 * ======================================
 */

/* stylelint-disable-next-line selector-class-pattern */
.u-umbrella-link {
  &::after {
    display: block;
    position: absolute;
    inset: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
  }

  &:focus {
    @include generic-focus;

    outline-color: currentcolor;
  }
}
