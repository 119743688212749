@import 'app.scss';
.link {
  @include typography-heading-5;

  display: flex;
  gap: spacing(1);
  align-items: center;
  color: inherit;
  font-weight: 700;
  text-underline-offset: 2px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
}

.icon {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}
