/**
 * ======================================
 * WAVE
 * ======================================
 */

@keyframes water {
  0% {
    transform: rotate(0);
  }

  20% {
    border-radius: 50% 63% 80% 49%;
  }

  40% {
    border-radius: 60% 64% 64% 48%;
  }

  60% {
    border-radius: 80% 63% 51% 49%;
  }

  80% {
    border-radius: 40% 60% 42% 58%;
  }

  100% {
    transform: rotate(180deg);
    border-radius: 62% 67% 70% 53%;
  }
}

@mixin wave-animation($color, $textColor: var(--color-text-base)) {
  position: relative;
  align-items: stretch;
  justify-content: center;
  transition: background-color 1s ease, color 1s ease;
  z-index: 1;
  overflow: hidden;

  &::before,
  &::after {
    position: absolute;
    top: 150%;
    transition: all 1.4s ease;
    z-index: -1;
    width: 200%;
    height: auto;
    content: '';
    aspect-ratio: 1;
  }

  &::after {
    opacity: 0.5;
    border-radius: 60% 70% 20% 40%;
    background-color: $color;
    animation: water 14s ease-in-out infinite both alternate;
  }

  &:hover,
  &:focus {
    background-color: $color;
    color: $textColor;

    &::before,
    &::after {
      top: -100%;
      opacity: 1;
    }
  }
}

/**
 * ======================================
 * ROTATE
 * ======================================
 */

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }

  100% {
    transform: rotate(360deg);
    transform-origin: center;
  }
}

@mixin rotate-animation {
  animation: rotate 0.75s linear 0s infinite;
}

/**
 * ======================================
 * PULSATE
 * ======================================
 */

@keyframes pulsate {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@mixin pulsate-animation {
  animation: pulsate 1s linear 0s infinite alternate;
}
