/**
 * Typography styling
 * Shared base styles for (responsive) typography sizes
 */

@mixin typography-body {
  line-height: 140%;
  font-size: 1.125rem;
  font-weight: 400;

  @include from-breakpoint(md) {
    font-size: 1.25rem;
  }
}

@mixin typography-intro {
  line-height: 140%;
  font-size: 1.125rem;
  font-weight: 500;

  @include from-breakpoint(md) {
    font-size: 1.25rem;
  }
}

@mixin typography-heading-1 {
  line-height: 120%;
  font-size: 2.5rem;
  font-weight: 900;

  @include from-breakpoint(md) {
    font-size: 3.5rem;
  }
}

@mixin typography-heading-2 {
  line-height: 120%;
  font-size: 2rem;
  font-weight: 900;

  @include from-breakpoint(md) {
    font-size: 2.75rem;
  }
}

@mixin typography-heading-3 {
  line-height: 120%;
  font-size: 1.5rem;
  font-weight: 900;

  @include from-breakpoint(md) {
    font-size: 2rem;
  }
}

@mixin typography-heading-4 {
  line-height: 120%;
  font-size: 1.25rem;
  font-weight: 900;

  @include from-breakpoint(md) {
    font-size: 1.5rem;
  }
}

@mixin typography-heading-5 {
  line-height: 120%;
  font-size: 1.125rem;
  font-weight: 900;

  @include from-breakpoint(md) {
    font-size: 1.25rem;
  }
}

@mixin typography-heading-6 {
  line-height: 120%;
  font-size: 1rem;
  font-weight: 900;

  @include from-breakpoint(md) {
    font-size: 1.125rem;
  }
}

@mixin typography-button {
  line-height: 120%;
  font-size: 1.25rem;
  font-weight: 900;

  @include from-breakpoint(md) {
    line-height: 133%;
    font-size: 1.5rem;
  }
}

@mixin typography-quote {
  line-height: 100%;
  font-family: var(--font-family-quote);
  font-size: 2.5rem;
  font-weight: 400;

  @include from-breakpoint(md) {
    font-size: 4rem;
  }
}
