@import 'app.scss';
/**
 * Footer
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.footer {
  // Keep the height in ratio of the width
  --border-top-height: calc(26 / 375 * 100vw);

  position: relative;
  z-index: 2;

  // Pull the footer over the last section, 16px is the largest bottom svg height of a section
  margin-top: calc(
    -1 * max(calc(26px + 16px), calc(var(--border-top-height) + 16px))
  );
  overflow: hidden;

  &::before {
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='375' height='26' viewBox='0 0 375 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M351.771 8.05536C359.44 8.68534 367.167 9.32009 375 9.94277V26H0V25.1035C20.8029 25.1036 42.6378 21.0431 68.4348 16.2458L68.4349 16.2458C105.67 9.32144 151.159 0.86207 213.714 0.862061C264.209 0.862053 307 4.37732 351.771 8.05536Z' fill='%23E62832'/%3E%3C/svg%3E%0A");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-width: 375px; // Width of the svg background-image
    height: max(26px, var(--border-top-height));
    content: '';
  }

  @include from-breakpoint(md) {
    --border-top-height: calc(19 / 1440 * 100vw);

    &::before {
      background-image: url("data:image/svg+xml, %3Csvg width='1440' height='19' viewBox='0 0 1440 19' xmlns='http://www.w3.org/2000/svg' fill='%23E62832' %3E%3Cpath d='M1173.5 0C1341.5 0 1440 4.97039 1440 4.97039V19H0V17.938C27.012 17.938 55.3639 15.0012 88.8607 11.5315C137.209 6.5235 196.275 0.405246 277.5 0.405239C343.067 0.405234 398.629 2.94767 456.763 5.60783C520.542 8.52628 587.419 11.5864 674 11.5865C756.44 11.5865 839.004 8.71153 921.878 5.8258C1005.37 2.91837 1089.19 0 1173.5 0Z' /%3E%3C/svg%3E");
      min-width: 1440px; // Width of the svg background-image
      height: max(19px, var(--border-top-height));
    }
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.wrapper {
  position: relative;
  z-index: 2; // Always overlap last pagepart elements border
  background-color: var(--color-bg-primary-brand);
  width: 100%;
  color: var(--color-fg-on-primary-brand);
}

.container {
  --vertical-spacing: #{spacing(5)};

  row-gap: spacing(6);
  padding-top: var(--vertical-spacing);
  padding-bottom: var(--vertical-spacing);

  @include from-breakpoint(md) {
    --vertical-spacing: #{spacing(10)};
  }
}

.group {
  grid-column: 1 / -1;

  @include from-breakpoint(sm) {
    grid-column: 1 / span 4;
  }

  @include from-breakpoint(md) {
    grid-column: span 6;
  }

  @include from-breakpoint(lg) {
    grid-column: span 3;
  }

  @include from-breakpoint(xl) {
    grid-column: span 4;

    &:nth-child(1) {
      grid-column: span 2;
    }

    &:nth-child(2) {
      grid-column: span 4;
    }

    &:nth-child(3) {
      grid-column: span 3;
    }

    &:nth-child(4) {
      grid-column: span 3;
    }
  }
}

.list {
  display: grid;
  gap: spacing(1);
  margin-bottom: 0;
}

.title {
  @include typography-heading-3;

  margin-bottom: spacing(3);
}

.text {
  margin-bottom: spacing(2);
}

.link {
  @include typography-heading-5;

  display: flex;
  gap: spacing(1);
  align-items: center;
  color: inherit;
  font-weight: 700;

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
}

.link.thin {
  font-weight: 500;
}

.socials {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(2);
}

.social {
  @include wave-animation(var(--color-bg-secondary-cta));

  display: block;
  border: none;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-bg-secondary-cta-hover);
  padding: spacing(1);
  color: var(--color-fg-on-default);
}

.socialIcon {
  width: 2rem;
  height: 2rem;
}

.bottom {
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  gap: spacing(4);

  @include from-breakpoint(sm) {
    grid-column: 1 / span 4;
  }

  @include from-breakpoint(md) {
    grid-column: 1 / -1;
  }

  @include from-breakpoint(lg) {
    flex-direction: row;
    grid-column: 1 / -1;
    justify-content: space-between;
  }
}

.bottomList {
  @include typography-intro;

  display: flex;
  flex-direction: column;
  gap: spacing(1);
  margin-bottom: 0;

  @include from-breakpoint(lg) {
    flex-direction: row;
    gap: spacing(6);
    align-items: center;
  }
}

.bottomLink {
  @include typography-intro;

  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
}

.logo {
  color: var(--color-fg-on-primary-brand);

  &:hover,
  &:focus {
    color: var(--color-red-100);
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

/* stylelint-disable-next-line selector-class-pattern */
.footer.MatchmakerPage {
  margin-top: 0;

  &::before {
    display: none;
  }
}
